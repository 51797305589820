import API from "@/apis/AccountingFirm/SuperAdminAccountingFirmProductApi";
import { NotImplementedYet, RequiredArguments } from "@/helpers/methods";

const Private = {
  requests: {
    accountingFirmProducts: {
      create: NotImplementedYet,
      read: API.sa.accountingFirmProduct.read,
      replace: API.sa.accountingFirmProduct.replace,
      delete: NotImplementedYet,
    }
  },
  services: {
    accountingFirmProducts: {
      create: NotImplementedYet,
      /**
       * Fetches products for a given accounting firm.
       * @param {number} accountingFirmId - The ID of the accounting firm.
       * @returns {Promise<Object>} A promise that resolves with the response of the GET request.
       */
      read: async function (accountingFirmId) {
        RequiredArguments({ accountingFirmId });
        return await Private.requests.accountingFirmProducts.read(accountingFirmId);
      },
      /**
       * Replace products for a given accounting firm.
       * This is a put operation, which means that the products will be replaced by the new ones.
       * And passing an empty array will remove all the products.
       * @param {number} accountingFirmId - The ID of the accounting firm.
       * @param {Array<Number>} products - The IDs of the products to save.
       * @returns {Promise<Object>} A promise that resolves with the response of the GET request.
       */
      replace: async function (accountingFirmId, products) {
        RequiredArguments({ accountingFirmId, products });
        return await Private.requests.accountingFirmProducts.replace(accountingFirmId, null, products);
      },
      delete: NotImplementedYet,
    }
  }
}

export default {
  replaceProducts: Private.services.accountingFirmProducts.replace,
  getProducts: Private.services.accountingFirmProducts.read,
}
