import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'

export default {
  sa: {
    accountingFirmProduct: {
      create: NotImplementedYet,
      read: async (accountingFirmId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) => {
        RequiredArguments({ accountingFirmId });
        return Backend.GET(
          URLS.api.sa.accountingFirms.products.uri,
          [accountingFirmId],
          params,
          doCancelPreviousRequest,
          doCancelAllOtherRequests
        );
      },
      replace: async (accountingFirmId, params = null, products = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) => {
        RequiredArguments({ accountingFirmId, products });
        return Backend.PUT(
          URLS.api.sa.accountingFirms.products.uri,
          [accountingFirmId],
          params,
          { products },
          doCancelPreviousRequest,
          doCancelAllOtherRequests
        );
      },
      delete: NotImplementedYet,
    }
  }
}
